var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Pagina niet gevonden")
            ])
          ]),
          _c("v-flex", { attrs: { sm12: "" } }, [
            _c("div", { staticClass: "elementPanel text-xs-center" }, [
              _c("img", {
                staticClass: "mb-4",
                attrs: {
                  width: "400px",
                  src: require("@/assets/images/test-trillendhuis.gif"),
                  alt: "IMG huisje"
                }
              }),
              _c("h3", { staticClass: "load-error load-error--text" }, [
                _vm._v("Sorry, we kunnen de pagina die u zocht niet vinden.")
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }