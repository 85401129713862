import { Component, Vue } from 'vue-property-decorator';

@Component<PageNotFound>({})
export default class PageNotFound extends Vue {
  public $pageTitle = 'Page Not Found';

  public mounted() {
    //
  }
}
